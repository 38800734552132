const register = {
    title: "Daftar Akun Mostrans",
    registerAs: "Silahkan pilih Daftar sebagai",
    shipper: "$t(common.shipper)",
    transporter: "$t(common.transporter)",
    alreadyHaveAnAccount: "Sudah Memiliki Akun?",
    registerShipperTitle: "Daftar Shipper Mostrans",
    registerTransporterTitle: "Daftar Transporter Mostrans",
    form: {},
};

register.form.dataPicPerusahaan = {
    title: "Data PIC Perusahaan",
};

register.form.dataPerusahaan = {
    title: "Data Perusahaan",
};

register.form.alamatPerusahaan = {
    title: "Alamat Perusahaan",
    findLocation: "Cari Lokasi",
};
register.form.dokumenPerusahaan = {
    title: "Dokumen Perusahaan",
    // findLocation: "Cari Lokasi",
};

register.form.verifikasiData = {
    title: "Verifikasi Data",
    notReceivedtheCode: "Belum mendapatkan kode?",
    resend: "Kirim Ulang",
};

export default register;
