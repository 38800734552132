import { useCallback,  } from "react";
import { useRecoilState } from "recoil";
import { GlobalLoadingAtom } from "../shared/atoms";

export function useGlobalLoading() {
    const [loading, setLoading] = useRecoilState(GlobalLoadingAtom);
    const wrapWithLoading = useCallback(
        async (promiseFn) => {
            setLoading(true);
            await promiseFn;
            setLoading(false);
        },
        []//eslint-disable-line
    );
    return [loading, setLoading, wrapWithLoading];
}
