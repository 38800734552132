import React from "react";
import { FullScreenLoading } from "@mostrans/web-components/components/base/Loading";
import { useGlobalLoading } from "@hooks/useGlobalLoading";
function GlobalLoading() {
    const [isLoading] = useGlobalLoading();
    return isLoading ? (
        <FullScreenLoading
            backdropProps={{ style: { zIndex: 10000 } }}
            loadingProps={{ style: { zIndex: 10000 } }}
            show
        />
    ) : (
        <React.Fragment />
    );
}

export default GlobalLoading;
