import { createApollo } from '@mostrans/web-components/helpers/apolloHelpers';
import { ENDPOINT_GRAPHQL_ADMIN } from '../constants';

const ApolloAdmin = createApollo({
    url: ENDPOINT_GRAPHQL_ADMIN,
    initialHeader: {},
});

export const ApolloAdminProvider = ApolloAdmin.ApolloProvider;
export const useApolloAdminClient = ApolloAdmin.useApolloClient;
export const useApolloAdminHeader = ApolloAdmin.useApolloHeader;
