const form = {
    username: {
        label: "Username",
        placeholder: "Silahkan masukan $t(form.username.label)",
    },
    userName: {
        label: "Nama User",
        placeholder: "Silahkan masukan $t(form.userName.label)",
    },
    email: {
        placeholder: "Silahkan masukan $t(form.email.label)",
        label: "Email",
    },
    address: {
        placeholder: "Silahkan masukan Detail $t(form.address.label)",
        label: "Alamat",
    },
    emailOrPhone: {
        placeholder: "Silahkan masukan $t(form.emailOrPhone.label)",
        label: "Email",
    },
    password: {
        placeholder: "Silahkan masukan $t(form.password.label)",
        label: "Password",
    },
    confirmationPassword: {
        placeholder: "Silahkan masukan $t(form.confirmationPassword.label)",
        label: "Konfirmasi Password",
    },
    companyName: {
        placeholder: "Silahkan masukan $t(form.companyName.label)",
        label: "Nama Perusahaan",
    },
    companyPhone: {
        placeholder: "Silahkan masukan $t(form.companyName.label)",
        label: "Nomor Kontak Perusahaan",
    },
    companyOpenDate: {
        label: "Jam Buka Perusahaan",
    },
    companyCloseDate: {
        label: "Jam Tutup Perusahaan",
    },
    phone: {
        placeholder: "08...",
        label: "No. Telp",
    },
    npwpImage: {
        label: "Foto NPWP",
    },
    siupImage: {
        label: "Foto SIUP",
    },
    tdpImage: {
        label: "Foto TDP",
    },
    akteImage: {
        label: "Foto Akte",
    },
    logo: {
        placeholder: "Silahkan masukan foto logo",
        upload: "Unggah foto logo",
        label: "Foto Logo",
    },
    // profilePicture: {
    //     placeholder: "Silahkan masukan $t(form.profilePicture.label)",
    //     label: "Foto Logo",
    // },
    emailVerificationCode: {
        label: "Kode Verifikasi Email",
        placeholder: "Silahkan masukan $t(form.emailVerificationCode.label)",
    },
    otpVerificationCode: {
        label: "Kode Verifikasi OTP",
        placeholder: "Silahkan masukan $t(form.otpVerificationCode.label)",
    },
    saasToken: {
        label: "Token Saas",
        placeholder: "Silahkan masukan $t(form.saasToken.label)",
    },
};


export default form