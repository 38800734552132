const pageName = {
    dashboard: "Dashboard",
    pengiriman: "Pengiriman",
    pengirimanDarat: "$t(pageName.pengiriman) Darat",
    pengirimanLaut: "$t(pageName.pengiriman) Laut",
    pesanan: "Pesanan",
    pesananDarat: "$t(pageName.pesanan) Darat",
    pesananLaut: "$t(pageName.pesanan) Laut",
    perencanaan: "Perencanaan",
    operasional: "Operasional",
    kontak: "Kontak",
    daftarPengguna: "Daftar Pengguna",
    laporan: "Laporan",
    full: "Full",
    claim: "Klaim",
    moscare: "Bantuan Moscare",
    detailPesanan: "Detail Pesanan",
    buatPesanan: "Buat Pesanan",
    konsolidasi: "Konsolidasi",
    // mappingKendaraan:''
};

export default pageName;
