import moment from "moment";
import { atom } from "recoil";

export const RouteHistoryAtom = atom({
    key: "RouteHistoryAtom",
    default: [],
});

export const ToggleRefreshParamAtom = atom({
    key: "ToggleRefreshParamAtom",
    default: false,
});

export const GlobalLoadingAtom = atom({
    key: "GlobalLoadingAtom",
    default: false,
});
export const UserIdAtom = atom({
    key: "UserIdAtom",
    default: null,
});

export const SideBarAtom = atom({
    key: "SideBarAtom",
    default: false,
});

// {
//     '13':[...]
// }

export const GlobalFilterAtom = atom({
    key: "GlobalFilterAtom_eei20ei0nf",
    default: {
        kontakAsal: null,
        kontakTujuan: null,
        kotaAsal: null,
        kotaTujuan: null,
        search: "",
        startDate: moment().subtract(1, "week"),
        endDate: moment(),
        jadwalKapalDate: moment(),
        statusOrder: null,
        statusTrip: null,
        statusJadwalKapal: null,
        ruteAsal: null,
        ruteTujuan: null,
        statusUser: null,
        hakAkses: null,
        // monthDate: moment(),
    },
});
