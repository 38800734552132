import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLocale, setLocale } from "../shared/helpers";
import Backend from "i18next-http-backend";
import id from "@/locales/id";
import resourcesToBackend from "i18next-resources-to-backend";
import { END_POINT_INTL, INTL_NAMESPACE } from "@/shared/constants/index";
import ChainedBackend from "i18next-chained-backend";
// import startCase from "lodash/startCase";

// i18n.services.formatter?.add("titleCase", (val) => startCase(val));

i18n.use(ChainedBackend)
    .use(initReactI18next)
    .init({
        load: "all",
        backend: {
            backends: [
                resourcesToBackend({
                    id,
                }),
                Backend,
            ],
            backendOptions: [
                {
                    loadPath: "/locales/{{ns}}/{{lng}}.json",
                },
                {
                    loadPath: END_POINT_INTL + "/locales/{{ns}}/{{lng}}.json",
                },
            ],
            // rel
        },
        lng: getLocale(),
        fallbackLng: "id",
        debug: true,
        ns: INTL_NAMESPACE,
        defaultNS: INTL_NAMESPACE,
        interpolation: {
            escapeValue: false,
        },
    });

i18n.on("languageChanged", (locale) => {
    setLocale(locale);
});
