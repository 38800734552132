import { createApollo } from "@mostrans/web-components/helpers/apolloHelpers";
import { ENDPOINT_GRAPHQL_MOSTRACK } from "../constants/constants";

const ApolloMostrack = createApollo({
    url: ENDPOINT_GRAPHQL_MOSTRACK,
    initialHeader: {},
});

export const ApolloMostrackProvider = ApolloMostrack.ApolloProvider;
export const useApolloMostrackClient = ApolloMostrack.useApolloClient;
export const useApolloMostrackHeader = ApolloMostrack.useApolloHeader;
