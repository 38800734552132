import {
    ThemeProvider,
    ApolloMarketPlaceProvider,
    GlobalCss,
    ToastContainer,
} from "@mostrans/web-components/providers";
import Routes from "./routes/Routes";
import {
    ENDPOINT_GRAPHQL_MP,
    // ENDPOINT_GRAPHQL_SAAS,
} from "@/shared/constants/index";
import GlobalLoading from "./components/GlobalLoading";
import { ApolloMostrackProvider } from "./shared/apollo/ApolloMostrack";
// import ErrorHandler from "@mostrans/web-components/components/base/ErrorHandler";
// import ErrorFallback from "./components/ErrorFallback";/
import ErrorHandler from "@mostrans/web-components/components/base/ErrorHandler";
import ErrorFallback from "@mostrans/web-components/components/pages/ErrorFallback";
import { ApolloMhelpProvider } from "./shared/apollo/ApolloMhelp";
import { ApolloAdminProvider } from "./shared/apollo/ApolloAdmin";
function App() {
    return (
        <ThemeProvider>
            <GlobalCss />
            <ErrorHandler onError={console.log} fallback={<ErrorFallback />}>
                <ApolloMarketPlaceProvider url={ENDPOINT_GRAPHQL_MP}>
                    <ApolloMostrackProvider>
                        <ApolloAdminProvider>
                            <ApolloMhelpProvider>
                                <GlobalLoading />
                                <ToastContainer limit={5} />
                                <Routes />
                            </ApolloMhelpProvider>
                        </ApolloAdminProvider>
                    </ApolloMostrackProvider>
                </ApolloMarketPlaceProvider>
            </ErrorHandler>
        </ThemeProvider>
    );
}

export default App;
