const common = {
    back: "Kembali",
    next: "Selanjutnya",
    submit: "Submit",
    login: "Login",
    register: "Register",
    shipper: "Shipper",
    transporter: "Transporter",
    delete: "Hapus",
    cancel: "Batal",
    save: "simpan",
    chooseLanguage: "Pilih Bahasa",
};

export default common;
