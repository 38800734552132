// import { DATE_FORMAT } from "@mostrans/web-components/constants/dateFormat";
import {
    // base64ToUtf8,
    // generateTemporaryUniqKey,
    utf8ToBase64,
} from "@mostrans/web-components/helpers/generalHelpers";
import { MIME_TYPE } from "@mostrans/web-components/constants";
// import Compressor from "compressorjs";
// import moment from "moment";
import React from "react";
import {
    URL_REDIRECT_SHIPPER,
    URL_REDIRECT_TRANSPORTER,
    URL_REDIRECT_ADMIN,
    ENDPOINT_GRAPHQL_MP,
    URL_REDIRECT_MOSTRACK,
    URL_REDIRECT_MHELP,
    URL_REDIRECT_WMS,
    URL_REDIRECT_MOSTRACK_ADMIN,
    // USER_TOKEN_KEY,
} from "./constants/index";
import Cookies from "js-cookie";
import Compressor from "compressorjs";
// import omit from "lodash/omit";
// import isPlainObject from "lodash/isPlainObject";
// import isArray from "lodash/isArray";
// import { atom, atomFamily } from "recoil";
// import Cookies from "js-cookie";
export const validateEmail = (str = "") => {
    const rgx =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    return rgx.test(str);
};

export const isEmail = validateEmail;
export const validatePassword = (str) => {
    const rgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\S]+$/;
    return rgx.test(str);
};

export const withProps = (Component, initialProps) =>
    React.forwardRef((props, ref) => {
        // console.log(initialProps);
        const mergedProps = { ...initialProps, ...props };
        // console.log("> mergedProps", mergedProps)
        if (ref) mergedProps.ref = ref;
        return <Component {...mergedProps} />;
    });

// export const isHttp = (url) => {
//     return /^https?:\/\//.test(url + "");
// };

export const isImageFile = (file) => {
    let isValid = false;
    const rgx = /\.(svg|png|jpeg|jpg)$/i;
    if (rgx.test(file.name)) {
        isValid = true;
    }
    return isValid;
};

export const isTime = (str) => {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(str);
};

export const isPhoneNumber = (text) => {
    return /^08\d{8,15}$/.test(text);
};

export const isDigit = (str) => {
    return /^\d+$/.test(str);
};

export const removeNonDigit = (str, { acceptDecimal } = {}) => {
    if (acceptDecimal) {
        str = str.replace(/,/g, ".");
        let matches = str.match(/(\d*[.]?\d*)/);
        // console.log("> matches", matches);
        if (!matches) return "";
        if (!matches[0]) return "";
        return matches[0].startsWith(".") || matches[0].startsWith(",")
            ? 0 + matches[0]
            : matches[0];
    }
    return str.replace(/\D/g, "");
};
export const getLocale = () => {
    return Cookies.get("locale") || "id";
};
export const setLocale = (locale) => {
    return Cookies.set("locale", locale);
};

// export const createFetchAndCacheAtom = () => {
//     return atom({
//         key: generateTemporaryUniqKey("FetchAndCacheAtom"),
//         default: createGetHooksResponse(),
//     });
// };
// export const createFetchAndCacheAtomWithKey = () => {
//     return atomFamily({
//         key: generateTemporaryUniqKey("FetchAndCacheAtomWithKey"),
//         default: () => createGetHooksResponse(),
//     });
// };

// export const createGetHooksResponse = () => ({
//     loading: false,
//     data: [],
//     error: null,
// });

// export const getLocation = async ({ location, placeId } = {}) => {
//     if (!window.google?.maps?.Geocoder) return null;
//     const geocoder = new window.google.maps.Geocoder();
//     const data = {
//         region: "IDN",
//     };
//     if (location) {
//         data.location = {
//             lat: location.lat,
//             lng: location.lng,
//         };
//     }
//     if (placeId) {
//         data.placeId = placeId;
//     }
//     return geocoder.geocode(data);
// };

export const matchInputPhoneNumber = (text) => {
    const regex = /(0$|08+\d*)/g;
    return text.match(regex)?.[0] || "";
};
// // window.moment=moment
// // moment.weekdays()
// export const getWeekDays = () => {
//     return moment.weekdays().map((day, id) => ({
//         day: day,
//         id: id + "",
//     }));
// };

// export const downloadImageFromFirebaseUrl = (
//     url,
//     fileName,
//     onSuccess,
//     onError
// ) => {
//     fetch(url, {
//         method: "GET",
//         headers: {},
//     })
//         .then((response) => {
//             response
//                 .arrayBuffer()
//                 .then((buffer) => {
//                     const url = window.URL.createObjectURL(new Blob([buffer]));
//                     const link = document.createElement("a");
//                     link.href = url;
//                     link.setAttribute("download", fileName + ".jpg"); //or any other extension
//                     document.body.appendChild(link);
//                     link.click();
//                     onSuccess?.();
//                 })
//                 .catch((err) => {
//                     console.log(err);
//                     onError?.();
//                 });
//         })
//         .catch((err) => {
//             console.log(err);
//             onError?.();
//         });
// };

// export const SEJAM = 1000 * 60 * 60;

// export const removeNok = (msg) => {
//     return msg?.replace(/^NOK\s*:/, "");
// };

// export const getParsedToken = () => {
//     const token = getToken();
//     // console.log("> token", token)
//     if (!token) throw Error("no token");
//     // const decodedToken = base64ToUtf8(token);
//     const parsedToken = JSON.parse(token);
//     return parsedToken;
// };

// export const constructAuthParam = (userData) => {
//     try {
//         const param = {
//             user_id: userData.id,
//             email: userData.email,
//             shipper_id: userData.shipper_id,
//             transporter_id: userData.transporter_id,
//             role: userData.role,
//             timestamp: moment()
//                 .subtract(1, "hour")
//                 .format(DATE_FORMAT.database),
//         };
//         const stringifiedParam = JSON.stringify(param);
//         const encodedParam = utf8ToBase64(stringifiedParam);
//         return "Bearer " + encodedParam;
//     } catch (error) {
//         console.log("> error", error);
//         return null;
//     }
// };

// export const encryptData = (str) => {
//     // console.log("> str", str)
//     // console.log(Cjs.AES);
//     // const encrypted = TripleDes.encrypt(str, CRYPTO_SECRET_KEY).toString(formatHex);
//     // const encStr =   encBase64.stringify(encUtf8.parse(encrypted))
//     // console.log("> encStr", encStr)
//     const encStr = utf8ToBase64(str);
//     //  CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
//     return encStr;
// };
// export const decryptData = (str) => {
//     // const decrypted = TripleDes.decrypt(str, CRYPTO_SECRET_KEY, {
//     // format: formatHex,
//     // });
//     // console.log("> decrypted", decrypted)
//     // const decStr = decrypted.toString(encUtf8);
//     const decStr = base64ToUtf8(str);
//     // console.log("> decStr", decStr)
//     // console.log("> decStr", decStr)
//     return decStr;
// };

// export const storeToken = (tokenData) => {
//     const encrypted = encryptData(tokenData);
//     localStorage.setItem(USER_TOKEN_KEY, encrypted);
// };
// export const getToken = () => {
//     const token = localStorage.getItem(USER_TOKEN_KEY);
//     // console.log("> token", token)
//     const decrypted = decryptData(token);
//     // console.log("> token", token)
//     return decrypted;
// };
// export const removeToken = () => {
//     return localStorage.removeItem(USER_TOKEN_KEY);
// };
export const getFileExtension = (file) => {
    if (!file?.name) throw Error("file name not found");
    return file.name.replace(/.*(\.\w+)$/, "$1");
};
// export const remove=()=>{}

export const compressImage = (imageFile, quality = 0.6) => {
    return new Promise((resolve, reject) => {
        new Compressor(imageFile, {
            mimeType: MIME_TYPE.jpeg,
            quality,
            success(imageFile) {
                resolve(imageFile);
            },
            error(error) {
                reject(error);
            },
        });
    });
};
export const loginShipper = (username, password) => {
    const json = JSON.stringify({ username, password });
    const base64 = utf8ToBase64(json);
    window.location.href = URL_REDIRECT_SHIPPER + "/" + base64;
};
export const loginTransporter = (username, password) => {
    const json = JSON.stringify({ username, password });
    const base64 = utf8ToBase64(json);
    window.location.href = URL_REDIRECT_TRANSPORTER + "/" + base64;
};
export const loginMostrack = (token) => {
    window.location.href = URL_REDIRECT_MOSTRACK + "/" + token;
};
export const loginWMS = (username, password) => {
    const json = JSON.stringify({ username, password });
    const base64 = utf8ToBase64(json);
    window.location.href = URL_REDIRECT_WMS + "/" + base64;
};
export const loginMhelp = (token) => {
    // const json = JSON.stringify({ username, password });
    // const base64 = utf8ToBase64(json);
    window.location.href = URL_REDIRECT_MHELP + "/" + token;
};
export const loginMostrackAdmin = (token) => {
    window.location.href = URL_REDIRECT_MOSTRACK_ADMIN + "/" + token;
};

export const loginAdmin = (username, password) => {
    const json = JSON.stringify({ username, password });
    const base64 = utf8ToBase64(json);
    window.location.href = URL_REDIRECT_ADMIN + "/" + base64;
};

export const isProduction = () => {
    // /hai/
    const rgx = /https:\/\/api\./gi;
    return rgx.test(ENDPOINT_GRAPHQL_MP);
};

export const withTimeout = (promise, timeout) => {
    return Promise.race([
        promise,
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Timeout")), timeout)
        ),
    ]);
};

// export const createTimeout = (ms) => {
//     const toCode = setTimeout(() => {
//         throw new Error("Operation Timeout!");
//     }, ms);
//     return () => clearTimeout(toCode);
// };
