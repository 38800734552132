import { createApollo } from "@mostrans/web-components/helpers/apolloHelpers";
import { ENDPOINT_GRAPHQL_MHELP } from "../constants/constants";

const ApolloMhelp = createApollo({
    url: ENDPOINT_GRAPHQL_MHELP,
    initialHeader: {

    },
});

export const ApolloMhelpProvider = ApolloMhelp.ApolloProvider;
export const useApolloMhelpClient = ApolloMhelp.useApolloClient;
export const useApolloMhelpHeader = ApolloMhelp.useApolloHeader;
