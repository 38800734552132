export const ROUTE = {
    login: {
        path: "login",
        fullPath: "/login",
        name: "login",
    },
    forgotPassword: {
        path: "forgot-password",
        fullPath: "/forgot-password",
        name: "forgotPassword",
    },
    register: {
        path: "register",
        fullPath: "/register",
        name: "register",
        transporter: {
            path: "transporter",
            fullPath: "/register/transporter",
            name: "registerTransporter",
        },
        shipper: {
            path: "shipper",
            fullPath: "/register/shipper",
            name: "registerShipper",
        },
    },
    notFound: {
        path: "404",
        fullPath: "/404",
        name: "notFound",
    },
};
