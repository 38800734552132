import React, { Suspense } from "react";
import { Navigate, Route, Routes as ReactRoutes } from "react-router";
import { ROUTE } from "@/shared/constants/index";
import { FullScreenLoading } from "@mostrans/web-components/components/base/Loading";
// import { renderRouteTransporter } from './RouteTransporter';
const Login = React.lazy(() => import("@pages/Login/Login"));
const Register = React.lazy(() => import("@pages/Register/Register"));

const RegisterTransporter = React.lazy(() =>
    import("@pages/Register/RegisterTransporter/RegisterTransporter")
);
const RegisterShipper = React.lazy(() =>
    import("@pages/Register/RegisterShipper/RegisterShipper")
);
const ForgotPassword = React.lazy(() =>
    import("@pages/ForgotPassword/ForgotPassword")
);
function Routes({ location }) {
    // console.log("> role", role)
    const defaultFallback = (
        <FullScreenLoading backdropProps={{ style: { zIndex: 999 } }} />
    );
    // const transporterRoute = useMemo(() => renderRouteTransporter(), []);
    return (
        <ReactRoutes location={location}>
            <Route
                path="/"
                element={<Navigate replace to={ROUTE.login.fullPath} />}
            />
            <React.Fragment>
                <Route
                    path={ROUTE.login.fullPath}
                    element={
                        <Suspense fallback={defaultFallback}>
                            <Login />{" "}
                        </Suspense>
                    }
                />
                <Route
                    path={ROUTE.forgotPassword.fullPath}
                    element={
                        <Suspense fallback={defaultFallback}>
                            <ForgotPassword />{" "}
                        </Suspense>
                    }
                />
                <Route path={ROUTE.register.fullPath}>
                    <Route
                        index
                        element={
                            <Suspense fallback={defaultFallback}>
                                <Register />{" "}
                            </Suspense>
                        }
                    />
                    <Route
                        path={ROUTE.register.transporter.path}
                        element={
                            <Suspense fallback={defaultFallback}>
                                <RegisterTransporter />{" "}
                            </Suspense>
                        }
                    />
                    <Route
                        path={ROUTE.register.shipper.path}
                        element={
                            <Suspense fallback={defaultFallback}>
                                <RegisterShipper />{" "}
                            </Suspense>
                        }
                    />
                </Route>
            </React.Fragment>
            {/* <Route path={ROUTE.notFound.path} element={<NotFound />} /> */}
            <Route
                path={ROUTE.notFound.path}
                element={<Navigate to="/" replace />}
            />
            <Route
                path="*"
                element={<Navigate to={ROUTE.notFound.fullPath} replace />}
            />
        </ReactRoutes>
    );
}

export default Routes;
