import { flags } from "../../assets";

const envVars = [
    // "REACT_APP_ENDPOINT_GRAPHQL_SAAS",
    "REACT_APP_ENDPOINT_GRAPHQL_MP",
    "REACT_APP_URL_REDIRECT_SHIPPER",
    "REACT_APP_URL_REDIRECT_ADMIN",
];
const err = new Error("Some variables not available in .env");
err.stacks = [];
envVars.forEach((varr) => {
    if (!varr) {
        err.stacks.push(process.env[varr]);
    }
});

if (err.stacks.length) {
    throw err;
}
export const END_POINT_API_KEY_GOOGLE_MAPS =
    "https://287nahjso7.execute-api.ap-southeast-1.amazonaws.com/default/mostrans_gmaps_apikey";
// console.log(process.env);
// export const ENDPOINT_GRAPHQL_SAAS =
//     process.env.REACT_APP_ENDPOINT_GRAPHQL_SAAS;
export const ENDPOINT_GRAPHQL_ADMIN =
    process.env.REACT_APP_ENDPOINT_GRAPHQL_ADMIN.trim();

export const ENDPOINT_GRAPHQL_MP =
    process.env.REACT_APP_ENDPOINT_GRAPHQL_MP.trim();
export const ENDPOINT_GRAPHQL_MOSTRACK =
    process.env.REACT_APP_ENDPOINT_GRAPHQL_MOSTRACK.trim();
export const ENDPOINT_GRAPHQL_MHELP =
    process.env.REACT_APP_ENDPOINT_GRAPHQL_MHELP.trim();
export const URL_REDIRECT_SHIPPER =
    process.env.REACT_APP_URL_REDIRECT_SHIPPER.trim();
export const URL_REDIRECT_ADMIN =
    process.env.REACT_APP_URL_REDIRECT_ADMIN.trim();
export const URL_REDIRECT_MOSTRACK =
    process.env.REACT_APP_URL_REDIRECT_MOSTRACK.trim();
export const URL_REDIRECT_MOSTRACK_ADMIN =
    process.env.REACT_APP_URL_REDIRECT_MOSTRACK_ADMIN.trim();
export const URL_REDIRECT_MHELP =
    process.env.REACT_APP_URL_REDIRECT_MHELP.trim();
export const URL_REDIRECT_WMS = process.env.REACT_APP_URL_REDIRECT_WMS.trim();

export const URL_REDIRECT_TRANSPORTER =
    process.env.REACT_APP_URL_REDIRECT_TRANSPORTER.trim();
export const GOOGLE_MAPS_API_KEY = "AIzaSyBMNZYb6VpeaKAD1HU5bYZRhxQOnUmF-tU";
export const INTL_NAMESPACE = "webReactShipperTransporter";
export const END_POINT_INTL = "http://localhost:3010";

export const LANGUAGE = {
    indonesia: {
        name: "Bahasa Indonesia",
        code: "id",
        flagUrl: flags.indonesia,
    },
    english: {
        name: "English",
        code: "en",
        flagUrl: flags.unitedStates,
    },
};

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyAQw21JarrfuOu1YK6fQspup8EpIuDtJH0",
    authDomain: "mostrans-storage.firebaseapp.com",
    databaseURL: "https://mostrans-storage.firebaseio.com",
    projectId: "mostrans-storage",
    storageBucket: "mostrans-storage.appspot.com",
    messagingSenderId: "108292050379",
    appId: "1:108292050379:web:581945489f5733cabcba4e",
};

export const SHIPPER_ID = {
    epm: "2",
};

export const CRYPTO_SECRET_KEY = "MoStRaNs";

export const USER_TOKEN_KEY = "MTRAUTH_TOKEN";

export const KEY_CODE = {
    backspace: "Backspace",
};

export const DRAWER_WIDTH = "230px";
export const NAVBAR_HEIGHT = "70px";

export const SOURCE = "WEB";

export const ROLE = {
    shipper: "SHIPPER",
    transporter: "TRANSPORTER",
    admin: "ADMIN",
    wms: "WAREHOUSE",
};

export const HAK_AKSES = {
    superAdmin: "SADM",
    pa: "PA",
    security: "SECURITY",
    admin: "ADMIN",
    user: "USER",
    sfin: "SFIN",
    checker: "CHECKER",
    tower: "TOWER",
    qa: "QA",
    adminfleet: "ADMFLT",
    adminEntry: "ADMIN ENTRY",
    private: "PRIVATE",
    adminV1: "ADMV1",
    finance: "FINANCE",
};

export const ERROR_TYPE = {
    graphqlCatchError: "CATCH_ERROR",
};

export const DEFAULT_KORDINAT = Object.freeze({
    lat: -6.189379,
    lng: 106.913012,
});

export const BISNIS_ID = {
    marketplace: "1",
    saas: "2",
    both: "3",
};
