import { INTL_NAMESPACE } from "../../shared/constants/index";
import common from "./common";
import form from "./form";
import login from "./login";
import register from "./register";
import pageName from "./pageName";

const id = {
    [INTL_NAMESPACE]: {
        common,
        form,
        login,
        register,
        pageName,
    },
};

export default id;
