import id from "moment/locale/id";
import en from "moment/locale/en-ca";
import moment from "moment";
import i18n from "i18next";
// console.log(id)
const locale = {
    id: id,
    en: en,
};

i18n.on("loaded", (lng) => {
    // console.log("> lng", Object.keys(lng)[0]);
    const lang = Object.keys(lng)[0];
    moment.updateLocale(lang, locale[lang] || locale.en);
});
window.moment = moment;
i18n.on("languageChanged", (lng) => {
    // console.log("> lng", lng)
    moment.updateLocale(lng, locale[lng] || locale.en);
    // console.log(moment.locale)
});
